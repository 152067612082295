import React from 'react';
import Layout from '../components/Layout/Layout';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faHandshake, faEdit } from '@fortawesome/free-regular-svg-icons';
import Seo from '../components/SEO/SEO';
import Button from '../components/UI/Button/Button';

const manufacServices = () => (
  <>
    <Layout headlines={["Services für Hersteller"]} className="centeredPage--TextPage">
      <h1>Was können wir für Ihr Tiny House Unternehmen tun?</h1>
      <p>Nachfolgend möchten wir Ihnen ein paar Möglichkeiten nennen, wie wir Ihr Tiny House Unternehmen unterstützen können.
      </p>

      <h2><FontAwesomeIcon icon={faEdit} /> Aufnahme in unsere Hersteller-Übersicht</h2>

      <p>Sprechen Sie exakt Ihre relevante Zielgruppe an, indem Sie Ihr Tiny House Unternehmen in die aktuell wahrscheinlich modernste <Link to="/hersteller/">Hersteller-Übersicht</Link> aufnehmen lassen.</p>
      <p>Und das beste daran: Wir sind auf der ersten Seite bei Google gelistet, wenn nach "tiny house hersteller" gesucht wird! Probieren Sie es selbst aus. </p>
      <p>Weiterhin sind wir stets darum bemüht, unsere Website-Nutzer und somit Ihre potenziellen Kunden auf der Suche nach dem passenden Hersteller bestmöglich zu unterstützen.
        Damit einhergehend bieten wir Ihnen tolle Möglichkeiten, Ihr Unternehmen ansprechend bei uns zu präsentieren.</p>
      <p>Voraussetzungen und Vorgehensweise</p>
      <ul> <li> Die Aufnahme Ihres <strong>Herstellereintrags</strong> auf unserer Website erfordert einen gewissen technischen Aufwand. Daher berechnen wir für das Anlegen Ihres Profils eine einmalige <strong>Aufwandspauschale von 79 €</strong> (inklusive Rechnung). <br/><br/> </li> <li> Das anschließende Führen Ihres Hersteller-Eintrags auf unserer Website ist aktuell noch <strong>kostenfrei</strong>. Dafür bitten wir Sie lediglich um eine kleine Gegenleistung: <br/><br/> Wir freuen uns, wenn Sie auf Ihrer Hersteller-Website einen Link zu uns setzen. Der Linktext sollte idealerweise lauten: <strong>"Aufgenommen bei Tiny-House-Helden"</strong>. Dieser Link sollte zu Ihrem persönlichen Hersteller-Profil auf unserer Website führen. <br/><br/> Bitte platzieren Sie den Link gut sichtbar, beispielsweise auf Ihrer Startseite oder im Footer – nicht versteckt im Impressum. <br/><br/> </li> <li> Sollten diese Konditionen nicht ganz Ihren Vorstellungen entsprechen, kontaktieren Sie uns bitte über unser <a href="/kontakt/"><strong>Kontaktformular</strong></a>. Wir finden gemeinsam sicher eine passende Lösung für Sie! <br/><br/> </li> <li> Wenn Sie mit den genannten Bedingungen einverstanden sind, klicken Sie bitte auf den unten stehenden Button <strong>"Zum Hersteller-Formular"</strong>. Dort können Sie bequem alle relevanten Angaben machen sowie Fotos hochladen. <br/><br/> Sobald Sie das Formular ausgefüllt haben, wird Ihr Eintrag in der Regel innerhalb weniger Tage veröffentlicht. Sollten wir Rückfragen an Sie haben, kontaktieren wir Sie selbstverständlich zuvor. </li> </ul>

      <Button type='xnarrow' ><a href="https://eu.jotform.com/form/81912935084360" target="_blank" className="none"> Zum Hersteller-Formular</a></Button>

      <p>Falls Sie darüber hinaus noch Fragen haben, können Sie hierfür ebenfalls gerne unser <a href='/kontakt/'>Kontaktformular</a> nutzen</p>

      {/* <p><em>Wichtiger Hinweis: Auf Grund der hohen Nachfrage nehmen wir <span className="underlined">vorübergehend</span> keine neuen Hersteller auf. Sollten Sie als Hersteller jedoch Interesse an einer Aufnahme haben, kontaktieren Sie uns gerne über unser <Link to="/kontakt/">Kontaktformular</Link>. Wir melden uns bei Ihnen, sobald wir das Formular für die Aufnahme neuer Hersteller wieder freischalten.</em></p> */}

      <h2><FontAwesomeIcon icon={faAlignLeft} /> Gastbeitrag</h2>

      <p>Als Tiny House Hersteller besitzen Sie sicherlich ein breites Wissen zum Thema Tiny Houses, das sogar weit über das Bauen von Häuschen hinausgeht. Nutzen Sie diese Kenntnisse, indem Sie Ihre potenziellen Käufer von Ihrem Wissen überzeugen! </p>

      <p>Hierzu bieten wir Ihnen an, einen von Ihnen verfassten Artikel zu einem bestimmten Thema auf unserer Website zu veröffentlichen und diesen mit Ihrem Namen zu versehen.</p>

      <p>Ihre Vorteile:</p>
      <ul className="text-list">
        <li>Überzeugen Sie potenzielle Käufer von Ihrem Wissen und schaffen Sie somit eine  Vertrauensbasis</li>
        <li>Steigern Sie den Wiedererkennungswert Ihres Namens bzw. Unternehmens</li>
        <li>Bessere Auffindbarkeit: Wir erwähnen mit jedem Artikel nicht nur Ihren Namen, sondern verlinken diesen auch direkt auf die Detail-Seite Ihres Herstellereintrages</li>
      </ul>


      <p>Voraussetzungen:</p>
      <ul className="text-list">
        <li>Kein Werbe-Text: Es muss sich um ein allgemeines Thema wie Finanzen, Statik, Straßenzulassung, Bauordnung, Belüftung, Licht oder Ähnliches handeln</li>
        <li>Mindestens 4.200 Zeichen Länge</li>
        <li>Der Text darf nicht wortwörtlich auf einer anderen Website öffentlich aufrufbar sein (schlecht für SEO wegen „Duplicate Content“)</li>
      </ul>

      <p>Denkbar sind natürlich auch Artikel bzw. Texte, die in Zusammenarbeit entstehen.</p>
      <p><em>Bei Interesse schreiben Sie uns einfach über das <Link to="/kontakt/">Kontaktformular</Link> und nennen Sie uns das Thema Ihres Artikels.</em></p>



      <h2><FontAwesomeIcon icon={faHandshake} />  Diverse Kooperationen</h2>

      <p>Wir sind offen für anderweitige Kooperationen, die auf dieser Seite nicht erwähnt werden. Uns ist es jedoch wichtig, dass alle Kooperationen möglichst transparent gestaltet werden. Das heißt, der Endnutzer bzw. potenzielle Tiny House Kunde soll über die Zusammenarbeit informiert werden. Das kann, je nach Fall, einfach auf unserer Website erfolgen.</p>

      <p><em>Sie haben eine Idee für eine mögliche Kooperation? Benutzen Sie auch hierfür bitte das <Link to="/kontakt/">Kontaktformular</Link> und beschreiben Sie uns Ihre Idee.</em></p>

      <h2><FontAwesomeIcon icon={faDesktop} />  Beratung und Unterstützung bei Ihrer Website</h2>

      <p>Durch unsere eigenen Recherchen haben wir festgestellt, dass viele Tiny House Hersteller leider über keine ansprechenden Websites verfügen. Mängel wie eine fehlende Optimierung für mobile Endgeräte  sind im digitalen Zeitalter leider enorm ungünstig für die Darstellung des eigenen Unternehmens.</p>

      <p>Als studierter Medieninformatiker und selbständiger Webentwickler bietet Ihnen unser Tiny House Held Michael an, Sie dahingehend zu unterstützen. Dabei kann er Sie gerne beraten oder auch bei der technischen Realisierung unterstützen.</p>

      <p><em><Link to="/kontakt">Kontaktieren</Link> Sie uns gerne mit Ihrem Anliegen</em></p>
    </Layout>
  </>
);

export default manufacServices;

export function Head() {

  return (
    <Seo
      title='Service für Hersteller | Tiny House Helden'
      meta_description=
      'Was können wir für Ihr Tiny House Unternehmen tun? Lassen Sie sich in unsere Liste aufnehmen und mehr.'
    />
  )
}